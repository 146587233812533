.bp3-dialog {
  background: white;
  .bp3-dialog-header {
    background: linear-gradient(
      90deg,
      rgba(0, 78, 35, 1) 0%,
      rgba(0, 157, 0, 1) 75%
    );
    h4 {
      color: white;
    }
    button {
      span {
        color: white;
      }
    }
  }
}
