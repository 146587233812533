@import "./font.scss";
@import "./typography.scss";
@import "./blueprint.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, Icons16, sans-serif;
}
:focus {
  outline-color: rgba(0, 157, 0, 0.6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  border: 0.5px solid #e7e7e7;
}

a {
  color: #538d2b;
}

.pseudo-link {
  background: none;
  border: 0;
  padding: 0;
  color: #538d2b;
  text-decoration: underline;
  cursor: pointer;
}

.bp3-dialog-container {
  height: 100vh;
  &:focus {
    outline: none;
  }
}
