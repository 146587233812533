.bp3-menu {
  a {
    color: #4a4a4a;
    text-decoration: none;
    &.active-sublink {
      .bp3-text-overflow-ellipsis {
        font-weight: bold;
      }
    }
  }
}
