.toast {
  .bp3-intent-success {
    background-color: #439809;
  }
  .bp3-intent-danger {
    background-color: #ff4d39;
  }
  .bp3-intent-warning {
    background-color: #fd7306;
  }
}
